import React from "react";
import  {Link} from 'react-router-dom';
import './header.css';
import $ from 'jquery';

import Logokehilat from '../Img.ico/תמונה23.png';
function Header() {
    const scrollToSection=()=>{
        window.scrollTo({
            top:document.body.scrollHeight,
            behavior:"smooth"
        })
        };
    $(function () {
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 50) {
                $("header").addClass("active");
            } else {
                $("header").removeClass("active");
            }
        });
    });
    // const handleChange = (e) => {
    //     window.location=e.target.value;
     
    //   };
  return (
    <header className="header">
      <div className="col1 ">
       <img id="logokehilat" src={Logokehilat} alt="logo kehilat"></img>
      </div>
        <div className="col2">
                <a href="/"  className="first_header"> בית </a>
                {/* <Link to="/sofrim" state={{id:"sofrim"}}style={{textDecoration:'none'}} className="first_header"><a > סופרי-סת"ם </a></Link> */}
               <Link to="/product" state={{id:"product"}} style={{textDecoration:'none'}} className="first_header"><a >מוצרי-יודאיקה </a></Link>
               {/* <Link to="/forum" state={{id:"forum"}} style={{textDecoration:'none'}} className="first_header"><a > פורום </a></Link> */}
                {/* <a href="/">מאמרים</a>  */}
                <a onClick={scrollToSection} className="first_header">צור-קשר</a>
        
        </div>
        <div className="col3">
            {/* <a href="/sign" className="hrefsign">הרשמה</a> */}
            <a href="/login" className="hreflogin">איזור אישי</a>
        </div>
        <div class="col4">
            {/* <a href="/" class="hrefhome">בית</a> */}
            <label className="lable-select">
<select value="menu" onChange={e=> window.location=e.target.value} className="hrefhome">
  <option value="menu">תפריט</option>
<option value="/">בית</option>
<option value="/login">איזור אישי</option>

{/* <option value="/product" state={{id:"product"}} >מוצרים נוספים</option> */}
{/* <option value="/product" state={{id:"sefer_tora"}} >ספרי תורה</option> */}
</select>

</label>
        </div>
        <div className="header-black">
            <div><Link to="/product" state={{id:"sefer_tora"}}style={{textDecoration:'none'}} >
            <a className="header-col">ספר תורה</a>
             </Link>
        </div> 
            <div><Link to="/product" state={{id:"mezuzot"}}style={{textDecoration:'none'}} >
            <a className="header-col">מזוזה</a>
              </Link>
        </div>
            <div><Link to="/product" state={{id:"tefilin"}} style={{textDecoration:'none'}}>
            <a className="header-col">תפילין</a>
              </Link>
        </div>
            <div><Link to="/product" state={{id:"megilot"}} style={{textDecoration:'none'}}>
            <a className="header-col">מגילה</a>
                </Link>
        </div>   
            <div><Link to="/product" state={{id:"pitum"}} style={{textDecoration:'none'}}>
            <a className="header-col">פיטום הקטורת</a>
                </Link>
        </div>   
            <div><Link to="/product" state={{id:"sgulot"}} style={{textDecoration:'none'}}>
            <a class="header-col">סגולות</a>
                </Link>
        </div>   
            {/* <div><Link to="/sofrim" state={{id:"magia"}} style={{textDecoration:'none'}}>
            <a class="header-col">  בדיקות סת"ם</a>
                </Link>
        </div>  */}
            {/* <div className="header-col8 ">
                <a href="/sifreitora" className="header-col">בית לספר תורה</a>
            </div>
            <div className="header-col9 ">
                <a href="/sifreitora" className="header-col">בית למגילה</a> 
             </div>  */}
        </div>

    </header>
  )
}

export default Header