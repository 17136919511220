import React from 'react'
import "./updatename.css";
function updatename() {
  function handleSubmit(e){
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const jsonData = {};
    for (let [key, value] of data) {
        jsonData[key] = value;
    }
    fetch("/updatename",
         {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(jsonData),
        }
    )
        .then((res) => res.json())
        .then((res) => {
            if (res.isToken) {
              alert("נשלח אליך מייל להמשך עידכון הפרטים");
              window.location="/updatename";
             } else {
              alert(res.err);
            }
        })
}
function deleteNameSubmit(){
  // const data = new FormData();
  // const jsonData = {};
  // for (let [key, value] of data) {
  //     jsonData[key] = value;
  // }
  // const params=window.location.href.split("?");
  // params.push(jsonData);
  fetch("/deletename",
       {
          method: "POST",
          headers: {
              "content-type": "application/json",
          }
          // ,body: JSON.stringify(params),
      }
  )
      .then((res) => res.json())
      .then((res) => {
          if(res.isFix){
              alert(res.err)
            window.location="/";
           } else {
              alert(res.err)
          }
      })
}
  return (
    <div class="home-updatename">                
    <h1>עידכון פרטים</h1>
    <form id="f-login" method="POST"  onSubmit={handleSubmit}>
        <label >הכנס מייל בעת ההרשמה
        <br/><input type="email" name="email"/></label>
        <br/><br/><input type="submit" value="שליחה"/>
    </form>
    <br/><label>מחיקה מהמערכת של קהילת-הסופרים<br/>אם ברצונך להימחק מאתר קהילת הסופרים, יש למחוק תחילה את המוצרים שקיימים במערכת!!
     <br/><br/><button className="btnforget"  onClick={deleteNameSubmit}>מחיקה מהמערכת</button></label>
    </div>  )
}

export default updatename