import React, { useState } from "react";
import './create.css';
function Create() {
    // const keyImg={};
    const [file, setFile] = useState("0");
    const [product, setProduct] = useState("0");
    const [type, setType] = useState("0");
    const [size, setSize] = useState("0");
    const [price, setPrice] = useState("0");
    const [count, setCount] = useState("0");
    const [text, setText] = useState("0");
    const fileChange = (e) => { 
          if (e.target.files[0].size >10 * 1024 * 1024) {
              alert('הקובץ גדול יותר מ 10mb'); 
              window.location="/create";//לנסות למצוא שיטה שרק מאתחלת את האינפוט ולא את כל הדף
              }
              setFile(e.target.files[0]);
             };
      const productChange = (e) => {
        setProduct(e.target.value);
      };
      const typeChange = (e) => {
        setType(e.target.value);
      };
      const sizeChange = (e) => {
        setSize(e.target.value);
      };
      const priceChange = (e) => {
        setPrice(e.target.value);
      };
      const countChange = (e) => {
        setCount(e.target.value);
      };
      const textChange = (e) => {
        setText(e.target.value);
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("product", product);
        formData.append("type", type);
        formData.append("size", size);
        formData.append("price", price);
        formData.append("count", count);
        formData.append("text", text);
   await fetch("/create",
        {
            method: "POST",
            body:formData
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.addit) {
                alert("התמונה נשלחה לבדיקה ותעלה לאתר לאחר אישורה, בהצלחה.");
                    window.location = "/create"
            } else {
                alert(res.err);
                window.location = "/create"
            }
        })
        .catch(err => console.error(err));
    }
  return (
    <div class="first">
        <h1>מוצר חדש</h1>
        <h2>כאן ניתן להוסיף תמונה. במידה וכבר קיים מוצר תואם, יש להסירו ולאחר מכן להעלות מוצר חדש</h2>
        <form id="f" enctype="multipart/form-data" method="POST"  onSubmit={handleSubmit}>
            <label>יש לבחור מוצר ולאחר מכן להעלות תמונה
            <br/>
            <select name="newProduct" id="newProduct" onChange={productChange}>
                <option value=""></option>
                <option value="mezuzot">מזוזה</option>
                <option value="sefer_tora">ספר תורה</option>
                <option value="megilot">מגילה</option>
                <option value="tefilin">תפילין</option> 
                <option value="pitum">פיטום הקטורת</option>
                <option value="sgulot">סגולות</option>
                <option value="product">מוצרים נוספים</option>
                <option value="teuda"> תעודת סופר או מגיה</option>
                <option value="img_user">תמונה של הסופר</option>
            </select></label>
            <br/><label>סוג הכתב
           <br/><select name="tipeKtav" id="tipeKtav" onChange={typeChange}>
           <option value=""></option>
            <option value="ספרדי">ספרדי</option>
            <option value="אשכנזי">אשכנזי</option>
            <option value='האריז"ל'>האריז"ל</option>
           </select></label>
           <br/><label>גודל המוצר
           <br/><input type="number" id="size" onChange={sizeChange}/></label>
           <br/><label >מחיר
           <br/><input type="number" id="price" onChange={priceChange}/></label>
           <br/><label>כמות
           <br/><input type="number" id="count" onChange={countChange}/></label>
           <br/><label>כמה מילים על המוצר
           <br/><input type="text" id="textProduct"  maxlength="100" onChange={textChange}/></label>
           <br/><label>תמונה של המוצר עד 10 מגה
           <br/><input type="file" id="imgSend" onChange={fileChange}/></label>
           <br/><br/><input type="submit" value="שליחה"/>
        </form>
    </div>
  )
}
export default Create