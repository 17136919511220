import React from 'react'
import "./forget.css";
function forget() {
  function handleSubmit(e){
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const jsonData = {};
    for (let [key, value] of data) {
        jsonData[key] = value;
    }
    fetch("/forget",
         {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(jsonData),
        }
    )
        .then((res) => res.json())
        .then((res) => {
            if (res.isToken) {
              alert("נשלח אליך מייל להמשך עידכון סיסמה");
              window.location="/login";
             } else {
              alert(res.err);
            }
        })
}
  return (
    <div class="home-forget">                
    <h1>עידכון סיסמה</h1>
    <form id="f-login" method="POST"  onSubmit={handleSubmit}>
        <label >הכנס מייל בעת ההרשמה
        <br/><input type="email" name="email"/></label>
        <br/><br/><input type="submit" value="שליחה"/>
    </form>
    </div>  )
}

export default forget