import React from 'react'

function fixupdatename() {
    function handleSubmit(e){
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const jsonData = {};
        for (let [key, value] of data) {
            jsonData[key] = value;
        }
        const params=window.location.href.split("?");
        params.push(jsonData);
        fetch("/fixupdatename",
             {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(params),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if(res.isFix){
                    alert("הפרטים האישיים שונו בהצלחה!")
                  window.location="/";
                 } else {
                    alert(res.err)
                }
            })
    }
      return (
        <div class="first">                
        <h1>עדכון פרטים אישיים</h1>
        <form id="f" method="POST"  onSubmit={handleSubmit}>
        <label >שם פרטי
        <br/><input type="text" name="first_name" id="first_name"/></label>  
        <br/><label >שם משפחה
        <br/><input type="text" name="last_name" id="last_name"/></label>
        <br/><label >טלפון
        <br/><input type="text" name="phone"id="phone"/></label> 
        <br/><label >עיר
        <br/><input type="text" name="city" id="city"/></label>
            <br/><br/><input type="submit" value="שליחה"/> 
             </form>
        </div> 
         )
}

export default fixupdatename