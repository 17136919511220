import React,{ useEffect, useState } from "react";
import { createElement } from 'react';
function Testimg() {

    const params =window.location.href.split("?");
    const [backendData,setData]=useState();
    useEffect(()=>{
        fetchtest()
    },[]); 
    const fetchtest=async()=>{
        const res= await fetch("/testimg", {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(params),
        })
         .then((res) => res.json())
            .catch((error) => console.log(error));
            setData(res);
            }
            function sharimg(product,id){
                const formData = new FormData();
                formData.append("product", product);
                formData.append("id", id);
                alert("האם אתה בטוח באישור התמונה?");
                const jsonData = {};
                for (let [key, value] of formData) {
                    jsonData[key] = value;
                }
                fetch("/shar-imgtest", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                    },
                    body:JSON.stringify(jsonData)
                     }).then((res) => res.json())
                    .then((res) => {
                        if (res.isToken) {
                            alert("התמונה אושרה בהצלחה");
                            window.location="/";
                        } else {
                            alert("התמונה לא אושרה בהצלחה");
                            window.location="/";
                        }
                    })
            }
            function deleteimg(product,id){
                const formData = new FormData();
                formData.append("product", product);
                formData.append("id", id);
                alert("האם אתה בטוח במחיקת התמונה?");
                const jsonData = {};
                for (let [key, value] of formData) {
                    jsonData[key] = value;
                }
                fetch("/delete-imgtest", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                    },
                    body:JSON.stringify(jsonData)
                     }).then((res) => res.json())
                    .then((res) => {
                        if (res.isToken) {
                            alert("התמונה נמחקה בהצלחה")
                        } else {
                            alert("התמונה לא נמחקה בהצלחה")
                        }
                    })
            }
  return (
    <div>
    {(typeof backendData==="undefined")?(<p>loading...</p>):
                  (<div id="divtd">
                    {console.log("bac test",backendData)};
                  {createElement("img",{src:backendData.url,id:"idimg"})} 
                  {createElement("button",{onClick:()=>{
                     sharimg(backendData.product,backendData.isToken)
                  }},"אישור תמונה")}
                  {createElement("button",{onClick:()=>{
                    deleteimg(backendData.product,backendData.isToken)
                  }},"מחיקת התמונה")}
                    
                  </div>)} 
         </div>
  )
}

export default Testimg