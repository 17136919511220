import React from 'react'

function validusers() {
    const params=window.location.href.split("?");
fetch("/validusers",{
          method:"POST",
          headers:{
              "content-type": "application/json"
          },
          body:JSON.stringify(params),
          }) 
          .then((res) => res.json())
          .then((res)=>{
            if(res.isToken){
            window.location="/login";
            }else{
            window.location="/";
            }
          })   
  return (
    <div>validusers</div>
  )
}

export default validusers