import React from 'react'
import "./login.css";
function Login() {
  function handleSubmit(e){
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const jsonData = {};
    for (let [key, value] of data) {
        jsonData[key] = value;
    }
    fetch("/login",
         {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(jsonData),
        }
    )
        .then((res) => res.json())
        .then((res) => {
            if (res.isToken) {
              window.location="/privat";
            } else {
              alert(res.err);
            }
        })
}
  return (
            <div class="home-login">
                <h1>התחברות</h1>
                <form id="f-login" method="POST"  onSubmit={handleSubmit}>
                    <label>אימייל
                    <br/><input type="email" name="email"/></label>
                    <br/><label>קוד
                    <br/><input type="password" name="password"/></label>
                   <br/><br/><input type="submit" value="שליחה"/>
                </form>
                <br/><br/><a href="/forget" ><button className="btnforget"> שכחתי סיסמא</button>  </a>
                <br/><br/><a href="/sign" ><button className="btnforget"> אין לי חשבון</button>  </a>

            </div>
  )
}

export default Login