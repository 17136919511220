import React from 'react';
import './footer.css';
function Footer() {
    function handleSubmit(e){
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const jsonData = {};
        for (let [key, value] of data) {
            jsonData[key] = value;
        }
        fetch("/tzor",
             {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(jsonData),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if (res.isToken) {
                    alert("ההודעה נשלחה בהצלחה.")
                    window.location = "/";
                } else {
                    alert("שליחת ההודעה נכשלה")
                }
            })
    }
  return (
    <footer className='footer'>
         <div className='menael'>
                 איך אני יכול לעזור לכם ?
                <form id="f" method="POST"  onSubmit={handleSubmit}>               
                    <input type="text" id="fname" name="firstname" placeholder="שם"/>
                    <input type="email" id="email" name="theemail" placeholder="אימייל"/>
                    <textarea id="subject" className='textarea' name="subject" placeholder="ההודעה שלך" 
                    // style="height:100px"
                    ></textarea>
                    <input type="submit" value="שליחה"/>
                </form>           
             <span >edf8422841@gmail.com 052.9936265</span>
            <div className="footer-black">
                <div className="foot-col1 ">
                    © כל הזכויות שמורות לאליהו פרש </div>
                <div className="foot-col2" text-align= "left">
                    <a href="/takanon">תקנון ומדיניות פרטיות </a>
                </div>
            </div>
</div>
     </footer>
  )
}

export default Footer